import axios from '@axios'

export default {
  namespaced: true,
  state: {
    pointListToUpdate: false,
    isSidebarActive: false,
    pointId: '',
    pointData: {files:[]},
    pointFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_POINT_LIST_UPDTAE(state, val){
      state.pointListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_POINT(state, val){
      state.pointId = '',
      state.pointData = {files:[]},
      state.pointFiles = []
    },
    SET_POINT_ID(state, val){
      state.pointId = val
    },
    SET_POINT_DATA(state, data){
      state.pointData = data
      state.pointFiles = data.files
    }
  },
  actions: {
    fetchPoints({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/point', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_POINT_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchPoint({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/point/${id}`)
          .then(response => {
            commit('SET_POINT_DATA', response.data.point)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updatePoint(ctx, {id, point}) {
      return new Promise((resolve, reject) => {
        axios.post(`/point/${id||''}`, { ...point })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disablePoint(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/point/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/point/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/point/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}

import { render, staticRenderFns } from "./PointList.vue?vue&type=template&id=3591bb5c&scoped=true&"
import script from "./PointList.vue?vue&type=script&lang=js&"
export * from "./PointList.vue?vue&type=script&lang=js&"
import style0 from "./PointList.vue?vue&type=style&index=0&id=3591bb5c&lang=scss&scoped=true&"
import style1 from "./PointList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3591bb5c",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QChip,QAvatar});

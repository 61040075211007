<template>
  <div id="point-list" class="rounded bg-white shadow">
    <PointSidebar :update="update"/>
    <div class="p-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start gap-2 mb-1 mb-md-0">
          <template v-if="showIcon">
            <fa-icon :icon="['fas', 'dot-circle']" size="2x" class="text-primary" />
            <span class="h4 m-0 text-primary">Points</span>
          </template>
          <div>
            <label class="text-capitalize">{{$t("table.showing")}}</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <template >
              <label>{{$t("table.of")}}</label>
              <v-select v-model="filterTable" label="title" :options="filterTableOptions" :reduce="a=>a.value" :clearable="false" class="filter-table-selector d-inline-block mx-50" >
                <template v-slot:option="eachStatus"><span class="text-nowrap">{{eachStatus.title}}</span></template>
              </v-select>
            </template>
            <label>{{$t("table.items")}}</label>
          </div>
        </b-col>
        <!-- Search -->
        <b-col cols="12" md="6" class="d-flex gap-2">
          <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search..." />
          <q-btn v-if="$can('create','Security')" push no-caps color="primary" @click="openSidebar('add')">Add</q-btn>
        </b-col>
      </b-row>
    </div>

    <b-table ref="refPointListTable" class="slim position-relative text-center" :items="fetchPoints" responsive :fields="tableColumns"
      primary-key="id" :sort-by.sync="sortBy" show-empty :sort-desc.sync="isSortDirDesc"
      :selectable="selectable?true:false" select-mode="multi" @row-selected="onRowSelected" >

      <template #empty>
        <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
        <h5>{{$t("table.noResult")}}</h5>
      </template>

      <!-- Header search -->
      <template #top-row="{ fields }">
        <td v-for="field in fields" :key="field.key" class="pl-0 pr-1">
          <div v-if="field.key=='selected'" class="text-center"><b-form-checkbox class="ml-1 d-inline-block" v-model="selectAllCheckbox"  @change="selectAll"/></div>
          <v-select v-else-if="field.key=='status'" label="title" v-model="filterSelects[field.key]" :options="$global.point_status" :reduce="a=>a.value" class="select-size-sm">
            <template v-slot:option="eachStatus">
              <fa-icon :icon="eachStatus.icon" fixed-width class="mr-1" :class="`text-${eachStatus.color}`"></fa-icon>{{eachStatus.title}}
            </template>
          </v-select>
          <v-select v-else-if="field.key=='pay.method'" label="title" v-model="filterSelects[field.key]" :options="$global.pay_methods" :reduce="a=>a.value" class="select-size-sm">
            <template v-slot:option="eachStatus">
              <fa-icon :icon="eachStatus.icon" fixed-width class="mr-1" :class="`text-${eachStatus.color}`"></fa-icon>{{eachStatus.title}}
            </template>
          </v-select>
          <div v-else-if="field.key=='actions'"></div>
          <div v-else-if="field.key=='paidAt'"></div>
          <b-form-input v-else-if="field.key" v-model="filterInputs[field.key]" size="sm" class="d-inline-block" :placeholder="field.label"/>
        </td>
      </template>

      <!-- Column: Select -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <!-- Column: Name -->
      <template #cell(name)="data">
        <!-- <div class="text-left">
          <fa-icon :icon="['fad','dot-circle']" class="mr-1 text-warning"/> {{data.item.name}}
        </div> -->
        <b-media vertical-align="center" class="text-left align-items-center" @click.stop="openSidebar('view', data.item)">
          <template #aside>
            <b-avatar size="32" :src="fileUrl(data.item.files?data.item.files[0]:'', 'point', true)" :variant="`light-primary`"><fa-icon v-if="!fileUrl(data.item.files?data.item.files[0]:'', 'point', true)" icon="map-marker-alt" /></b-avatar>
          </template>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-nowrap h5 m-0">{{ data.item.name }}</div>
            <div>
              <fa-icon v-if="data.item.disabled" :icon="['fad','ban']" class="text-danger mr-1" />
              <fa-icon :icon="['far','chevron-right']" class="text-primary mx-50"/>
            </div>
          </div>
        </b-media>
      </template>

      <!-- Column: Site -->
      <template #cell(site)="data">
        <div class="d-flex flex-column gap-1 align-items-start text-nowrap">
          <q-chip v-if="data.item.site" color="light-primary" dense class="my-0 text-nowrap d-flex"><q-avatar icon="home" color="primary" text-color="white" />{{data.item.site}}</q-avatar></q-chip>
        </div>
      </template>

      <!-- Column: Address -->
      <template #cell(address)="data">
        <div class="d-flex flex-column gap-1 align-items-start">
          <div class="text-nowrap">{{data.item.address}}</div>
          <div class="d-flex flex-wrap text-left gap-1">
            <q-chip v-if="data.item.zip" color="light-primary" dense class="my-0"><q-avatar icon="place" color="primary" text-color="white" />{{data.item.zip}}</q-avatar></q-chip>
            <span class="text-nowrap">{{data.item.unit}}</span>
          </div>
        </div>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="data">
        <div class="d-flex flex-wrap gap-1">
          <q-chip v-for="(tag, index) in data.item.tags" dense color="light-primary" :key="tag.index">{{tag}}</q-chip>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex gap-3 align-items-center justify-content-center">
          <feather-icon @click.stop="openSidebar('view', data.item)" icon="EyeIcon" class="cursor-pointer" size="16"/>
          <feather-icon v-if="$can('update','Security')" @click.stop="openSidebar('update', data.item)" icon="EditIcon" class="text-primary cursor-pointer" size="16"/>
          <feather-icon v-if="$can('delete','Security') && !data.item.disabled" @click.stop="disablePoint(data.item._id)" icon="XIcon" class="text-danger cursor-pointer" size="16"/>
        </div>
      </template>
    </b-table>

    <div class="p-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items</span>
        </b-col>

        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalpoints" :per-page="perPage"
            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18" /></template>
            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
          </b-pagination>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BTable, BMedia, BAvatar, 
  BBadge, BPagination, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ref, watch } from '@vue/composition-api'
import { fileUrl, date } from '@core/utils/filter'
import store from '@/store'
import usePoint from './usePoint'
import PointSidebar from './PointSidebar'

export default {
  name: 'point-list',
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BFormCheckbox,

    vSelect,
    //VueSelect,

    PointSidebar
  },
  props:{
    showIcon:{
      type: Boolean,
      default: false
    },
    selectable:{
      type: Boolean,
      default: false
    }
  },
  filters:{
    date
  },
  setup() {

    const {
      // Sidebar
      pointId,
      // Actions
      fetchPoints,
      disablePoint,
      fetchPoint,
      refetchData,
      

      // Table items
      refPointListTable,
      tableColumns,
      perPage,
      currentPage,
      totalpoints,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,
      // Filter
      filterInputs,
      filterSelects,
      filterTable, filterTableOptions,
    } = usePoint()

    // sidebar
    const update = ref(null) // true for [update, add]
    watch(pointId, val=>{ //放在这里避免多次请求
      if(val) fetchPoint(val)
    })

    watch(()=>store.state.point.pointListToUpdate, val=>{
      if(val)refPointListTable.value.refresh()
    })

    const openSidebar = function(type, point){
      if (type==='add'){
        update.value = true
        store.commit('point/SET_POINT_ID', '')
        store.commit('point/TOGGLE_SIDEBAR', true)
      } else if (type==='update') {
        if (!point ||!point._id) return 
        update.value = true
        store.commit('point/SET_POINT_ID', point._id)
        store.commit('point/TOGGLE_SIDEBAR', true)
      } else if(type==='view'){
        if (!point ||!point._id) return 
        update.value = false
        store.commit('point/SET_POINT_ID', point._id)
        store.commit('point/TOGGLE_SIDEBAR', true)
      }
    }

    return {
      // Actions
      disablePoint,
      fetchPoints,
      refetchData,
      
      // Sidebar
      update,
      openSidebar,

      refPointListTable,
      tableColumns,
      perPage,
      currentPage,
      totalpoints,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,  
      // Table filters
      filterInputs,
      filterSelects,
      filterTable, filterTableOptions,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,

      // Filter
      fileUrl,      
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filter-table-selector{
  min-width: 90px
}
</style>

<style lang="scss">
#point-list {
  .q-avatar__content {
    font-size:1rem
  }
  .q-chip__content{
    flex-wrap: nowrap
  }
}

</style>
